import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './HomeCareRequest.css';
import { fetchUserProfile, fetchHomeCare, saveHomeCareRequestMetaDoc } from '../services/Service';
import axios from "axios";
import { MultiSelect } from 'react-multi-select-component';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { formatDateTime } from '../../../met/utils/CommonLibrary';

const HomeCareRequestMetaDoc = ({ closeModal, selectedServiceRecord, callHomeBasedServicesHistory, selectedBulkServices }) => {

    const qsParams = useParams();
    const userId = qsParams.memberId;

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [cityName, setCityName] = useState("");
    const [categoryName, setCategoryName] = useState("");
    
    const [providerList, setProviderList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [disabledButton, setDisabledButton] = useState(true);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [formData, setFormData] = useState({
        userId: userId,
        selectedServiceRecord: selectedServiceRecord,
        membership: '',
        name: '',
        approvalNumber: '',
        mobileNumber: '',
        deductible: '',
        partnerClinic: 'Direct Request',
        city: '',
        district: '',
        remarks: '',
        nationality: '',
        homeHealthCare: '',
        labProviderName: '',
        requestedHomeHealth: '',
        selectedUserNames: '',
        category: '',
        product: '',
        selectedDate: '',
        viewDate: '',
        cityId: ''
    });

    useEffect(() => {
        fetchUserData();
        dropdownData();
    }, [])


    const fetchUserData = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        setDisabledButton(true);
        await fetchUserProfile(userId,'')
            .then((response) => {
                if (response.data.code === 200) {
                    setFormData((prev) => ({ ...prev, membership: response.data.data.info.membershipNumber, name: response.data.data.info.memberName, mobileNumber: response.data.data.info.mobile, nationality: response.data.data.info.nationality }));
                    setDisabledButton(false);
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const dropdownData = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchHomeCare('')
            .then((response) => {
                if (response.data.code === 200) {
                    setCityList(response.data.data.city);
                    setProductList(response.data.data.products);
                    setCategoryList(response.data.data.category);

                    if (selectedBulkServices.length > 0) {
                        findServicesByLabel(response.data.data.service);
                    }
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const findServicesByLabel = (sl) => {
        const foundServices = sl.filter(service => selectedBulkServices.includes(service.label));
        handleChangeService(foundServices);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if(name == "category"){

            setFormData((prevData) => ({
                ...prevData,
                product: '',
                category: value
            }));

            setSelectedOptions([]);
            setCategoryName(value);
            setProductList('');
            fetchCategoryList(value, cityName);
            return;
        }
        
        const updatedFormData = { ...formData, [name]: value };

        if (name === "homeHealthCare") {
            updatedFormData.labProviderName = e.target.selectedOptions[0].text;
        }
        setFormData(() => { return updatedFormData });

        setError('');
    };

    const setSelctedDate = async (date) => {
        setFormData({
            ...formData,
            ['viewDate']: date,
            ['selectedDate']: formatDateTime(date),
        });

        setError('');
    };
   

    const fetchCategoryList = async (categoryName,cityName) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchHomeCare(categoryName,cityName)
            .then((response) => {
                if (response.data.code === 200) {
                    if(response.data.data.products){
                        setProductList(response.data.data.products);
                    }else{
                        setProductList([]);
                    }
                    
                    setProviderList(response.data.data.provider);
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    const handleChangeCity = (selectedOption) => {
        setCityName(selectedOption.value);
        setProviderList([])
        fetchCategoryList(categoryName,selectedOption.value);
        setFormData({
            ...formData,
            ['city']: selectedOption.label,
            ['cityId']: selectedOption.value,
        });
        setError('');
    };

    

    const validateForm = () => {
        const mandatoryFields = ['membership', 'name', 'mobileNumber', 'partnerClinic', 'deductible', 'homeHealthCare', 'category', 'city','selectedDate','product'];
        
        for (let field of mandatoryFields) {

            if(field === 'product'){
                if(productList){
                    if(!formData[field].trim()){
                        setError('Enter all mandatory fields');
                        return false;
                    }
                }
            }else if (field === 'city') {
                if (formData[field] == "") {
                    setError('Enter all mandatory fields');
                    return false;
                }
            }else if (field === 'selectedDate') {
                if (formData[field] == "" || formData[field] == undefined) {
                    setError('Enter all mandatory fields');
                    return false;
                }
            }else if (!formData[field].trim()) {
                setError('Enter all mandatory fields');
                return false;
            }

           
        }

        return true;
    };

    const handleSubmit = async (event) => {

        setError('');
        event.preventDefault();
        if (validateForm()) {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            setDisabledButton(true);
            await saveHomeCareRequestMetaDoc(formData)
                .then((response) => {
                    if (response.data.code === 200) {
                        setMessage(response.data.data.message);
                        callHomeBasedServicesHistory();
                        setTimeout(() => {
                            closeModal();
                        }, 1000);
                    } else {
                        setMessage('');
                        setError(response.data.data.message);
                        setDisabledButton(false);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    const handleChangeService = (selected) => {
        setSelectedOptions(selected);
        const selectedValues = selected.map(option => option.value).join(',,');
        // setFormData({ ...formData, product: selectedValues });
        setFormData((prevData) => ({
            ...prevData,
            product: selectedValues
        }));
    };

    useEffect(() => {
        if (formData['viewDate']) {
            const now = new Date();
            if (formData['viewDate'] < now) {
            const roundedNow = roundToNext15Minutes(now);
            // setSelectedDate(roundedNow);
            setFormData({
                ...formData,
                ['viewDate']: roundedNow,
                ['selectedDate']: formatDateTime(roundedNow),
            });
            }
        }
    }, [formData['viewDate']]);

    const roundToNext15Minutes = (date) => {
        const roundedDate = new Date(date);
        const minutes = roundedDate.getMinutes();
        const remainder = 15 - (minutes % 15);
        roundedDate.setMinutes(minutes + remainder);
        roundedDate.setSeconds(0);
        roundedDate.setMilliseconds(0);
        return roundedDate;
    };

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedTime = new Date(time);

        // Allow only times equal to or greater than the current time for today
        return selectedTime.getTime() >= currentDate.getTime();
    };

    return (
        <>
            <div className='col-md-12'>
                <div className='card card-task-appoint' style={{ overflow: "visible" }}>
                    <div className='card-body'>
                        <div className='card-text'></div>
                        <form id="medicationForm" onSubmit={handleSubmit}>

                            <div className='row '>

                                <div className='col-md-6 mb-3 mt-2'>
                                    <div className='row'>
                                        <div className='col-md-6 mb-4'>
                                            <label className="form-label">Membership
                                                <span className='text-danger'>*</span></label>
                                            <h4 name="membership">{formData['membership']}</h4>
                                        </div>

                                        <div className='col-md-6 mb-4'>
                                            <label className="form-label">Name
                                                <span className='text-danger'>*</span></label>
                                            <h4 name="name">{formData['name']}</h4>
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">Mobile Number
                                                <span className='text-danger'>*</span>
                                            </label>
                                            {/* <h4 name="mobileNumber">{formData['mobileNumber']}</h4> */}
                                            <input type="text" name="mobileNumber" className='form-control control-input-ui' value={formData['mobileNumber']} onChange={handleChange} />
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">Deductible
                                                <span className='text-danger'>*</span>
                                            </label>
                                            <input type="text" name="deductible" className='form-control control-input-ui' value={formData['deductible']} onChange={handleChange} />
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">City
                                                <span className='text-danger'>*</span>
                                            </label>
                                            <Select
                                                value={cityList.find(option => option.label === formData.city) || null}
                                                onChange={handleChangeCity}
                                                options={cityList}
                                                isSearchable={true}
                                                placeholder="Select City"
                                                className="form-control select-input-ui select-input-ui-select"
                                            />
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">Home Health care provider Name
                                                <span className='text-danger'>*</span>
                                            </label>
                                            <select className="form-select select-input-ui" name="homeHealthCare" id="homeHealthCare" value={formData['homeHealthCare']} onChange={handleChange} >
                                                <option value="">--Select Home Health care--</option>
                                                {providerList?.map(option => (
                                                    <option key={option.id} value={option.id}>{option.providerName}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className='col-md-12 mb-2'>
                                            <label className="form-label">Preferred Appointment Date<span className='text-danger'>*</span>
                                            </label>
                                            <div className="date-picker-wrapper" style={{ position: 'relative' }}>
                                                {/* <DatePicker
                                                    selectsRange={false}
                                                    selected={formData['viewDate']}
                                                    onChange={(date) => setSelctedDate(date)}
                                                    dateFormat="dd MMMM yyyy"
                                                    minDate={new Date()}
                                                    placeholderText="Select Date"
                                                    className="form-control input-field-ui"
                                                    isClearable={false}
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                /> */}
                                                <DatePicker
                                                    selectsRange={false}
                                                    selected={formData['viewDate']}
                                                    onChange={(date) => setSelctedDate(date)}
                                                    showTimeSelect
                                                    timeFormat="h:mm aa"
                                                    timeIntervals={15}
                                                    dateFormat="dd MMMM yyyy, h:mm aa"
                                                    minDate={new Date()}
                                                    filterTime={filterPassedTime}
                                                    placeholderText="Select Date and Time"
                                                    className="form-control input-field-ui"
                                                    isClearable={false}
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                />
                                                <i className='bx bx-calendar'
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        pointerEvents: 'none',
                                                        fontSize: '20px',
                                                        color: '#666'
                                                    }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className='col-md-6 mb-2 mt-1'>
                                    <div className='row'>
                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">Approval Number
                                            </label>
                                            <input type="text" name="approvalNumber" className='form-control control-input-ui' value={formData['approvalNumber']} onChange={handleChange} />
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">Partner Clinic
                                                <span className='text-danger'>*</span>
                                            </label>
                                            <input type="text" name="partnerClinic" className='form-control control-input-ui' disabled={true} value={formData['partnerClinic']} onChange={handleChange} />
                                        </div>

                                        <div className='col-md-6 mb-2'>
                                            <label className="form-label">District</label>
                                            <input type="text" name="district" className='form-control control-input-ui' value={formData['district']} onChange={handleChange} />
                                        </div>

                                        <div className='col-md-6 mb-2'>
                                            <label className="form-label">Nationality<span className='text-danger'>*</span>
                                            </label>
                                            <select className="form-select select-input-ui" name="nationality" id="nationality" value={formData['nationality']} onChange={handleChange} >
                                                <option value="">--Select Nationality--</option>
                                                <option value="Saudi Arabian">Saudi Arabian</option>
                                                <option value="Non Saudi Arabian">Non Saudi Arabian</option>
                                            </select>
                                        </div>            
                                        

                                        <div className='col-md-6 mb-2 mt-2'>
                                            <label className="form-label">Category
                                                <span className='text-danger'>*</span>
                                            </label>
                                            <select className="form-select select-input-ui" name="category" id="category" value={formData['category']} onChange={handleChange} >
                                                <option value="">--Select Category--</option>
                                                {categoryList?.map(option => (
                                                    <option key={option.categoryName} value={option.categoryName}>{option.label}</option>
                                                ))}
                                            </select>
                                        </div>

                                        
                                        <div className='col-md-6 mb-2 mt-2'>
                                            {(formData['category'] == "radiology" || formData['category'] == "pathology" || formData['category'] == "physiotherapy_procedure") && productList ?
                                                <>
                                                    <label className="form-label">Home Health Product
                                                        <span className='text-danger'>*</span>
                                                    </label>
                                                    <MultiSelect
                                                        options={productList}
                                                        value={selectedOptions}
                                                        onChange={handleChangeService}
                                                        labelledBy="Select"
                                                        hasSelectAll={false}
                                                        disableSearch={false}
                                                        overrideStrings={{
                                                            selectAll: 'Select All',
                                                            allItemsAreSelected: 'All items are selected',
                                                            selectSomeItems: 'Select items',
                                                        }}
                                                        className="form-select select-input-ui select-input-ui-select"
                                                        name="requestedLabTest"
                                                        id="requestedLabTest"
                                                    />
                                                </>
                                                :
                                                <>
                                                    {productList && formData['category'] != "" &&
                                                        <>
                                                            <label className="form-label">Home Health Product
                                                                <span className='text-danger'>*</span>
                                                            </label>
                                                            
                                                            <select className="form-select select-input-ui" name="product" id="product" value={formData['product']} onChange={handleChange} >
                                                                <option value="">Select Product</option>
                                                                {productList?.map(option => (
                                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                                ))}
                                                            </select>
                                                        </>
                                                    }
                                                </>
                                                }
                                        </div>

                                        <div className='col-md-12 mb-3 mt-3'>
                                            <label className="form-label">Remarks</label>
                                            <input type="text" name="remarks" className='form-control control-input-ui' value={formData['remarks']} onChange={handleChange} />
                                        </div>
                                        {/* <div className='col-md-12 mb-3'>
                                            {selectedUserNames}
                                        </div> */}
                                    </div>



                                </div>
                                <div className='col-md-12 text-center mt-2'>
                                    <div className={`${error != "" ? "errorDiv" : "hide"}`}>{error}</div>
                                    <div className={`${message != "" ? "messageDiv" : "hide"}`}>{message}</div>
                                    <Button variant="primary" type="submit" className="btn btn-primary save-btn me-3" disabled={disabledButton}>Save Home Care Request</Button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeCareRequestMetaDoc;